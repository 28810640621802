<template>
  <div class="relative">
    <button class="btn btn-light atualizar" :disabled="loading" @click="refresh">
      <font-awesome-icon :icon="['fas', 'sync-alt']"  :class="loading && 'fa-spin'" style="margin:0!important"/>
    </button>
    <div v-if="!items.length && !loading" class="not-found">
      <font-awesome-icon :icon="['fas', 'unlink']" size="6x" class="mb-3"/>
      <h2>Você ainda não enviou nenhuma oferta ;(<br/><br/>
        <router-link to="/">Clique aqui</router-link> e veja os veículos
      </h2>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div :class="'item '+i.statusClass" v-for="i in items" :key="i.dataAtualizacao">
          <div class="header">
            <span class="status">
              {{i.statusFormatado}}
            </span>
            <span class="data-status">
              <small>Atualizado </small> {{i.dataAtualizacao}}
            </span>
          </div>
          <div class="body">
            <h5>{{i.marca}} {{i.modelo}} </h5>
            <div class="row">
              <div class="col-lg-5">
                <div class="row">
                  <div class="col">
                    <router-link :to="i.codigoNegociacao || '#'" class="img-container">
                      <div class="img-container">
                        <img class="w-100" :src="i.imagemCapaFormatadoUrl"/>
                      </div>
                    </router-link>
                  </div>
                  <div class="col">
                    <ul>
                      <li>
                        <div class="icon"><font-awesome-icon :icon="['far', 'calendar-alt']"  /></div> {{i.anoModelo}}/{{i.anoFabricacao}}
                      </li>
                      <li>
                        <div class="icon"><font-awesome-icon :icon="['fas', 'gas-pump']"  /></div> {{i.combustivel}}
                      </li>
                      <li>
                        <div class="icon"><font-awesome-icon :icon="['fas', 'map-pin']"  /></div> {{i.transmissao}}
                      </li>
                    </ul>
                  </div>
                  <div class="col">
                    <ul>
                      <li>
                        <div class="icon"><font-awesome-icon :icon="['fas', 'tachometer-alt']"  /></div> {{i.kmFormatado}}km
                      </li>
                      <li>
                        <div class="icon"><font-awesome-icon :icon="['fas', 'sign']"  /></div> {{i.placaFormatado}}
                      </li>
                      <li>
                        <div class="icon"><font-awesome-icon :icon="['fas', 'paint-brush']"  /></div> {{i.cor}}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="oferta">
                  <span class="valor">{{i.valorOfertaFormatado}}</span>
                  <span class="data-envio">enviada {{i.dataOferta}}</span>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="mensagem">
                  <label>Mensagem:</label>
                  <span class="descricao">{{i.mensagem}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div class="loading mb-3" >
          <font-awesome-icon :icon="['fas', 'sync-alt']" spin v-if="loading"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { library } from '@fortawesome/fontawesome-svg-core'
  import { faGasPump, faMapPin, faTachometerAlt,faSign,faPaintBrush,faUnlink} from '@fortawesome/free-solid-svg-icons'
  import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
  import {relativeTime} from '@/services/util';
  import api from '@/services/api';
import {companyPath} from '@/services/auth';

  library.add(faCalendarAlt,faGasPump,faMapPin,faTachometerAlt,faSign,faPaintBrush,faUnlink);

  export default {
  data() {
    return {
      items: [],
      total: 0,
      loading: true,
      pagination: {
        NumeroPagina: 1,
        TamanhoPagina: 8,
        DeslocamentoPagina: 1
      },
      hasMore: false,
    }
  },
  methods: {
    async get() {
      this.loading = true;
      
      let isFirstPage = this.pagination.NumeroPagina === 1;

      if(isFirstPage) {
        this.hasMore = true;
      }
      let response = await api.get('/v1/lojistas/ofertas', {
        params: {
          ...this.pagination,
        },
      });

      let { data, totalRegistros} = response.data;

      for(let i in data) {
        data[i].dataAtualizacao = relativeTime(data[i].dataAtualizacao || data[i].dataOferta);
        data[i].dataOferta = relativeTime(data[i].dataOferta);
        data[i].statusClass = this.getStatusClass(data[i].status);
        // data[i].imagemCapaFormatadoUrl = `${location.protocol}//${companyPath}.repasys.com.br${data[i].imagemCapaUrl}`

      }

      this.items = isFirstPage ? data : [ ...this.items,...data];
      this.total = isFirstPage ? totalRegistros : this.total;

      this.hasMore = data.length === this.pagination.TamanhoPagina;
      this.loading = false;
    },
    getMore() {
      let that = this;
      let footerHeight = document.querySelector('.footer-layout').scrollHeight + 
                         document.querySelector('.copyright').scrollHeight;
      window.onscroll = async () => {
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight + footerHeight >= document.documentElement.offsetHeight;
        
        if (bottomOfWindow && that.hasMore && !that.loading) {
          that.loading = true;
          that.pagination.NumeroPagina++;
          await that.get();
        }
      }
    },
    getStatusClass(status) {
      switch(status) {
        case 1:
          return 'warning';
        case 2: 
          return 'success';
        default: 
          return 'danger'
      }
    },
    async refresh() {
      this.pagination.NumeroPagina = 1;
      await this.get();
    }
  },
  async mounted(){
    await this.get();
    await this.getMore();
  }
}
</script>
<style scoped>
    .loading {
    text-align: center;
    min-height: 25px;
    /* margin:0; */
  }
  .item{
    border: 1px solid var(--light);
    padding: 1.5rem;
    background: var(--white);
    margin-bottom:1.5rem;
    border-radius: 4px;
  }

  .item .header{
    padding-bottom:1.5rem;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--light);
  }
  .item .header .status{
    font-weight: 600;
  }
  .item.warning{
    border-left: 4px solid var(--warning);
  }
  .item.success{
    border-left: 4px solid var(--success);
  }
  .item.danger{
    border-left: 4px solid var(--danger);
  }

  .item.warning .status{
    color: var(--warning)
  }
  .item.danger .status{
    color: var(--danger)
  }
  .item.success .status{
    color: var(--success)
  }

  .body{
    padding-top:1.5rem;
  }

  .body .img-container{
    height: 100px;
    background-color: var(--darker);
    width: 100%;
    justify-content: center;
    align-content: center;
    align-items: center;
    overflow:hidden;
    position:relative;
  }
  .body .img-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
  }
  ul{
    list-style: none;
    padding: 0 0 0 0px;
    margin: 0;
    justify-content: space-around;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
  }
  li{
    font-weight: 600;
    text-transform: uppercase;
    font-size: 11px;
    line-height: 1.4;
    overflow: hidden;
    color: var(--dark);
    white-space: nowrap;
  }
  

  li .icon{
    width: 13px;
    display:inline-block;
    text-align:center;
        line-height: 1.67;
  }
  h5{
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
    margin: 0;
    padding: 0 0 21px 0;
  }

  .oferta {
    height: 100%;
    display: flex;
    /* justify-content: center; */
    align-content: center;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
  }
  .oferta .valor{
    font-size: 24px;
    font-weight: 600;
    /* margin-bottom: 0.75rem */
  }
  .oferta .data-envio{
    font-size: 12px;
  }
  .not-found{
    text-align:center;
  }
  .mensagem {
    font-size: 12px;
    display: flex;
    flex-direction:column;
  }
  .mensagem label{
    font-weight: 600;
  }
  .data-status{
    text-align: right;
  }
  .atualizar{
    position: absolute;
    top:-86px;
    right:0;
  }
  .relative{
    position:relative;
    
  }
</style>
